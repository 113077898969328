@font-face
  font-display: swap
  font-family: 'BreeSerif'
  font-weight: 400
  src: url('/assets/fonts/BreeSerif-Regular.ttf') format('truetype')

@font-face
  font-display: swap
  font-family: 'OpenSans'
  font-weight: 300
  src: url('/assets/fonts/OpenSans-Light.ttf') format('truetype')

@font-face
  font-display: swap
  font-family: 'OpenSans'
  font-weight: 400
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype')

@font-face
  font-display: swap
  font-family: 'OpenSans'
  font-weight: 600
  src: url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype')

html, body
  margin: 0
  padding: 0
  position: relative

  *
    box-sizing: border-box
    position: relative
    font-family: "OpenSans", serif
    margin: 0
    padding: 0
    box-sizing: border-box

  button
    outline: none
    border: none

body
  overflow-x: hidden

@media only screen and(max-width: 768px)

  body
    overflow-x: hidden

body.bs-modal-open
  overflow: hidden

.fb-customerchat
  display: inline!important
  z-index: 3000!important
.fb-customerchat+div
  z-index: 2999!important
  visibility: hidden
  pointer-events: none

.introjs-helperLayer
  background-color: rgba(255, 255, 255, .5)

.introjs-tooltipReferenceLayer
  z-index: 1000000000000000000
  visibility: visible

.flatpickr-confirm
  background-color: #336699
  color: white
  padding: .5rem

  svg
    display: none

  &:hover
    cursor: pointer
    background-color: #264C72
//otp
.otp-input
  font-size: 25px !important
  padding: 0px !important
  width: 40px
  height: 55px
  @media screen and (max-width:450px)
    width: 30px !important
    height: 45px !important

ng-otp-input
  .wrapper
    text-align: center

.wrapper
  justify-content: center !important

.wrapper-flex
  justify-content: center !important
  display: flex
//otp
.flatpickr-calendar
  transform-origin: top left !important
  transform: scale(.8) !important

  &.animate
    animation: none !important

.modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: rgba(128,128,128,0.5)
  // display: none
  z-index: 3

.cdk-overlay-pane
  max-width: 100vw !important


@import '~bootstrap/scss/bootstrap-reboot'
@import '~bootstrap/scss/bootstrap-grid'
@import "~bootstrap/dist/css/bootstrap.css"

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap'

@import "styles/_variables"
@import "styles/_mixins"
